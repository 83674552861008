import { Middleware } from 'redux';
import { SEND_COMMAND, LocationsActionTypes, RUN_SCENE } from 'types';
import * as schedulerService from 'services/scheduler.service';

export const locationsMiddleware: Middleware = store => next => async (action: LocationsActionTypes) => {
  switch (action.type) {
    case SEND_COMMAND: {
      await schedulerService.sendCommand(action.locationId, action.deviceId, action.level);

      return;
    }
    case RUN_SCENE: {
      await schedulerService.runSceneById(action.locationId, action.sceneId);

      return;
    }
    default: {
      return next(action);
    }
  }
};
