import { createSelector } from 'reselect';
import { RootState } from 'reducers/root.reducer';
import { Dictionary } from 'lodash';

const getFilters = (state: RootState): Dictionary<string> => state.filters;

export const filtersSelector = createSelector<RootState, Dictionary<string>, Dictionary<string>>(
  [getFilters],
  filters => filters,
);
