import 'components/Locations/Locations.scss';
import React, { useState } from 'react';
import { Hubs, Location } from 'types';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { GoAlert } from 'react-icons/go';
import { Button, Input, Modal } from 'antd';
import InsteonLogo from 'images/InsteonLogo.png';
import ConnectToHubModal from 'components/Locations/ConnectToHubModal';
import { IoMdClose } from 'react-icons/io';
import * as schedulerService from 'services/scheduler.service';

interface LocationCardProps {
  location: Location;
  openEditModal: () => void;
}

const LocationCard: React.FC<LocationCardProps> = props => {
  const { location, openEditModal } = props;

  const [expanded, setExpanded] = useState(false);
  const [showConnectToHubModal, setShowConnectToHubModal] = useState(false);
  const [memberInput, setMemberInput] = useState('');
  const [addMemberLoading, setAddMemberLoading] = useState(false);
  const [userDeleting, setUserDeleting] = useState(false);
  const [hubDisconnecting, setHubDisconnecting] = useState(false);

  const addMember = async () => {
    setAddMemberLoading(true);
    await schedulerService.addUserToLocation(location.id, memberInput);
    setAddMemberLoading(false);
    setMemberInput('');
  };

  const handleDeleteUser = (email: string) => {
    Modal.confirm({
      title: 'Remove Member',
      content: 'Are you sure you want to remove this member?',
      maskClosable: true,
      icon: (
        <GoAlert style={{ color: 'red', fontSize: '22px', float: 'left', marginRight: '16px', marginBottom: '20px' }} />
      ),
      okText: 'Remove',
      onOk: async () => {
        setUserDeleting(true);
        await schedulerService.removeUserFromLocation(location.id, email);
        setUserDeleting(false);
      },
      okButtonProps: {
        type: 'primary',
        danger: true,
        loading: userDeleting,
      },
    });
  };

  const handleDisconnectFromHub = (hub: Hubs) => {
    Modal.confirm({
      title: 'Disconnect From Hub',
      content: 'Are you sure you want to disconnect this hub?',
      maskClosable: true,
      icon: (
        <GoAlert style={{ color: 'red', fontSize: '22px', float: 'left', marginRight: '16px', marginBottom: '20px' }} />
      ),
      okText: 'Disconnect',
      onOk: async () => {
        setHubDisconnecting(true);
        await schedulerService.disconnectFromHub(location.id, hub);
        setHubDisconnecting(false);
      },
      okButtonProps: {
        type: 'primary',
        danger: true,
        loading: hubDisconnecting,
      },
    });
  };

  const renderLocationZipcode = () => {
    return <div className='locationZipcode'>{location.zipcode}</div>;
  };

  const renderLocationOwner = () => {
    return <div className='locationOwner'>{location.owner}</div>;
  };

  const renderLocationMembers = () => {
    const locationMembers = location.users.map(user => {
      return (
        <div key={user} className='editLocationMember'>
          <div className='editLocationMemberName'>{user}</div>
          <div className='editLocationMemberRemoveIcon' onClick={() => handleDeleteUser(user)}>
            {user !== location.owner && <IoMdClose />}
          </div>
        </div>
      );
    });

    return (
      <div className='editLocationMembersWrapper'>
        <div className='editLocationMemberInput'>
          <Input size='small' allowClear onChange={e => setMemberInput(e.target.value)} value={memberInput} />
          <Button
            className='addMemberButton'
            type='primary'
            onClick={addMember}
            size='small'
            loading={addMemberLoading}
          >
            Add
          </Button>
        </div>
        <div className='editLocationMemberList'>{locationMembers}</div>
      </div>
    );
  };

  const renderLocationHubs = () => {
    const hubs = location.hubs.map(hub => {
      return (
        <div key={hub} className='editLocationHub'>
          <div className='editLocationCardHub'>
            <img src={InsteonLogo} alt='' />
          </div>
          <div className='editLocationCardHubRemoveIcon' onClick={() => handleDisconnectFromHub(hub)}>
            <IoMdClose />
          </div>
        </div>
      );
    });

    return (
      <div className='locationCardHubWrapper'>
        {hubs}
        <Button type='primary' onClick={() => setShowConnectToHubModal(true)} block>
          Connect to Hub
        </Button>
      </div>
    );
  };

  const renderSection = (title: string, content: JSX.Element | JSX.Element[]) => {
    return (
      <div className='locationCardSectionWrapper'>
        <div className='locationCardSectionTitle'>{title}</div>
        <div className='locationCardSectionContent'>{content}</div>
      </div>
    );
  };

  const locationCardBodyClass = expanded ? 'visible' : 'hidden';

  return (
    <div className='locationCard'>
      <div className='locationCardHeader'>
        <div className='locationCardTitle' onClick={() => setExpanded(!expanded)}>
          {location.name}
        </div>
        <div className='locationCardStatus'>
          <MdEdit className='editLocationButton' onClick={openEditModal} />
          <div className='locationCardExpandIcon' onClick={() => setExpanded(!expanded)}>
            {expanded ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>
      </div>
      <div className={`locationCardBody ${locationCardBodyClass}`}>
        {renderSection('Zipcode', renderLocationZipcode())}
        {renderSection('Owner', renderLocationOwner())}
        <div className='divider' />
        <div className='editLocationMembersSection'>
          <div className='editLocationTitle'>Members</div>
          {renderLocationMembers()}
        </div>
        <div className='divider' />
        <div className='editLocationHubsWrapper'>
          <div className='editLocationTitle'>Hubs</div>
          {renderLocationHubs()}
        </div>
      </div>
      <ConnectToHubModal
        locationId={location.id}
        showModal={showConnectToHubModal}
        onClose={() => setShowConnectToHubModal(false)}
      />
    </div>
  );
};

export default LocationCard;
