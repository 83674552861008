import { SET_CURRENT_TAB, UrlActionTypes } from 'types';

export default function currTabReducer(state: string = '', action: UrlActionTypes): string {
  switch (action.type) {
    case SET_CURRENT_TAB: {
      return action.tab;
    }
    default: {
      return state;
    }
  }
}
