export const SYNC_DEVICES = 'SYNC_DEVICES';
export const SET_DEVICES_SYNCING = 'SET_DEVICES_SYNCING';

interface SyncDevices {
  type: typeof SYNC_DEVICES;
}

interface SetDevicesSyncing {
  type: typeof SET_DEVICES_SYNCING;
  syncing: boolean;
}

export type DevicesActionTypes = SyncDevices | SetDevicesSyncing;
