export enum DayCategory {
  EVERYDAY = 'EVERYDAY',
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SHABBAT = 'SHABBAT',
  WEEKDAY = 'WEEKDAY',
  WEEKEND = 'WEEKEND',
  NOT_SHABBAT = 'NOT SHABBAT',
  NOT_CHAG = 'NOT CHAG',
  NOT_EREV = 'NOT EREV',
  NOT_SHABBAT_AND_NOT_CHAG = 'NOT SHABBAT AND NOT CHAG',
  EREV = 'EREV',
  EREV_CHAG = 'EREV CHAG',
  CHAG_EREV_SHABBAT = 'CHAG EREV SHABBAT',
  SHABBAT_EREV_CHAG = 'SHABBAT EREV CHAG',
  SHABBAT_CHAG = 'SHABBAT CHAG',
  NOT_SHABBAT_EREV_CHAG = 'NOT SHABBAT EREV CHAG',
  CHAG = 'CHAG',
  SHABBAT_OR_CHAG = 'SHABBAT OR CHAG',
  CHAG_FIRST_DAY = 'CHAG FIRST DAY',
  CHAG_SECOND_DAY = 'CHAG SECOND DAY',
  CHOL_HAMOED = 'CHOL HAMOED',
  EREV_ROSH_HASHANAH = 'EREV ROSH HASHANAH',
  ROSH_HASHANAH = 'ROSH HASHANAH',
  RH_FIRST_DAY = 'ROSH HASHANAH FIRST DAY',
  RH_SECOND_DAY = 'ROSH HASHANAH SECOND DAY',
  EREV_YOM_KIPPUR = 'EREV YOM KIPPUR',
  YOM_KIPPUR = 'YOM KIPPUR',
  EREV_PESACH = 'EREV PESACH',
  PESACH = 'PESACH',
  PESACH_CHOL_HAMOED = 'PESACH CHOL HAMOED',
  PESACH_FIRST_DAY = 'PESACH FIRST DAY',
  PESACH_SECOND_DAY = 'PESACH SECOND DAY',
  PESACH_THIRD_DAY = 'PESACH THIRD DAY',
  PESACH_FOURTH_DAY = 'PESACH FOURTH DAY',
  PESACH_FIFTH_DAY = 'PESACH FIFTH DAY',
  PESACH_SIXTH_DAY = 'PESACH SIXTH DAY',
  PESACH_SEVENTH_DAY = 'PESACH SEVENTH DAY',
  PESACH_EIGHTH_DAY = 'PESACH EIGHTH DAY',
  EREV_SHAVUOT = 'EREV SHAVUOT',
  SHAVUOT = 'SHAVUOT',
  SHAVUOT_FIRST_DAY = 'SHAVUOT FIRST DAY',
  SHAVUOT_SECOND_DAY = 'SHAVUOT SECOND DAY',
  EREV_SUKKOT = 'EREV SUKKOT',
  SUKKOT = 'SUKKOT',
  SUKKOT_FIRST_DAY = 'SUKKOT FIRST DAY',
  SUKKOT_SECOND_DAY = 'SUKKOT SECOND DAY',
  SUKKOT_THIRD_DAY = 'SUKKOT THIRD DAY',
  SUKKOT_FOURTH_DAY = 'SUKKOT FOURTH DAY',
  SUKKOT_FIFTH_DAY = 'SUKKOT FIFTH DAY',
  SUKKOT_SIXTH_DAY = 'SUKKOT SIXTH DAY',
  SUKKOT_SEVENTH_DAY = 'SUKKOT SEVENTH DAY',
  SUKKOT_CHOL_HAMOED = 'SUKKOT CHOL HAMOED',
  EREV_SHEMINI_ATZERET = 'EREV SHEMINI ATZERET',
  SHEMINI_ATZERET = 'SHEMINI ATZERET',
  SIMCHAT_TORAH = 'SIMCHAT TORAH',
}

export enum EventCategory {
  SUNRISE = 'Sunrise',
  SUNSET = 'Sunset',
  CANDLE_LIGHTING = 'Candle Lighting',
  HAVDALAH = 'Havdalah',
}
