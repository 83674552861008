import 'components/User/User.scss';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers/root.reducer';
import { auth } from 'fire';
import { Button } from 'antd';

interface UserProps {}

const User: React.FC<UserProps> = props => {
  const logout = () => {
    auth.signOut();
    window.location.reload();
  };

  return (
    <div className='userWrapper'>
      <Button onClick={logout}>logout</Button>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

export default connect(mapStateToProps)(User);
