import {
  FiltersActionTypes,
  SET_DEVICES_FILTER,
  SET_EVENTS_FILTER,
  SET_LOCATIONS_FILTER,
  SET_SCENES_FILTER,
} from 'types';

export function setDevicesFilter(filter: string): FiltersActionTypes {
  return {
    type: SET_DEVICES_FILTER,
    filter,
  };
}

export function setScenesFilter(filter: string): FiltersActionTypes {
  return {
    type: SET_SCENES_FILTER,
    filter,
  };
}

export function setEventsFilter(filter: string): FiltersActionTypes {
  return {
    type: SET_EVENTS_FILTER,
    filter,
  };
}

export function setLocationsFilter(filter: string): FiltersActionTypes {
  return {
    type: SET_LOCATIONS_FILTER,
    filter,
  };
}
