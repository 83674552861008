import 'index.scss';
import 'antd/dist/antd.css';
import 'react-calendar/dist/Calendar.css';
import ReactDOM from 'react-dom';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { Provider } from 'react-redux';
import store from 'store';
import { auth, analytics } from 'fire';
import Login from 'components/Login/Login';
import { initializeUser } from 'actions';
import * as schedulerService from 'services/scheduler.service';

auth.onAuthStateChanged(async user => {
  if (user) {
    await schedulerService.createUser(user.email!);

    analytics.setUserId(user.uid);
    analytics.setUserProperties({ userId: user.uid, email: user.email });

    const app = (
      <Provider store={store}>
        <App />
      </Provider>
    );

    store.dispatch(initializeUser(user.email!));

    ReactDOM.render(app, document.getElementById('root'));
  } else {
    ReactDOM.render(<Login />, document.getElementById('root'));
    window.history.replaceState({}, '', '/');
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
