import 'components/Events/Events.scss';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Event, EventTime, Location, Scene, ScheduledTime, SCHEDULED_TIME_TYPE } from 'types';
import { MdEdit } from 'react-icons/md';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { RootState } from 'reducers/root.reducer';
import { scenesSelector, locationsSelector } from 'selectors';
import { format, setHours, setMinutes } from 'date-fns';
import { Switch } from 'antd';
import * as schedulerService from 'services/scheduler.service';

interface EventsProps {
  event: Event;
  openEditModal: () => void;
  scenes: Scene[];
  locations: Location[];
}

const EventCard: React.FC<EventsProps> = props => {
  const { event, openEditModal, scenes, locations } = props;

  const [expanded, setExpanded] = useState(false);
  const [enabled, setEnabled] = useState(event.enabled);

  const onChangeEnabled = async (checked: boolean) => {
    setEnabled(checked);
    await schedulerService.updateEvent(event.locationId, {
      ...event,
      enabled: checked,
    });
  };

  const renderEventLocation = () => {
    const location = locations.find(l => l.id === event.locationId)!;

    return <div className='eventCardSectionDescription'>{location.name}</div>;
  };

  const renderEventCategories = () => {
    return event.categories.map((category, index) => {
      return (
        <div className='eventCardTag' key={index}>
          {category}
        </div>
      );
    });
  };

  const renderEventScenes = () => {
    return event.scenes.map((sceneId, index) => {
      const scene = scenes.find(s => s.id === sceneId);

      if (!scene) {
        return <></>;
      }

      return (
        <div className='eventCardTag' key={index}>
          {scene.name}
        </div>
      );
    });
  };

  const renderEventTime = (eventTime: EventTime | ScheduledTime) => {
    const eventString = () => {
      if (eventTime.type === SCHEDULED_TIME_TYPE) {
        const date = setHours(setMinutes(new Date(), eventTime.minute), eventTime.hour);

        return format(date, 'h:mm aaa');
      } else {
        if (eventTime.offset) {
          const modifier = eventTime.offset < 0 ? 'before' : 'after';

          return `${Math.abs(eventTime.offset)} minutes ${modifier} ${eventTime.eventCategory}`;
        }

        return eventTime.eventCategory;
      }
    };

    return <div className='eventCardSectionDescription'>{eventString()}</div>;
  };

  const renderSection = (title: string, content: JSX.Element | JSX.Element[]) => {
    return (
      <div className='eventCardSectionWrapper'>
        <div className='eventCardSectionTitle'>{title}</div>
        <div className='eventCardSectionContent'>{content}</div>
      </div>
    );
  };

  const eventCardBodyClass = expanded ? 'visible' : 'hidden';

  return (
    <div className='eventCard'>
      <div className='eventCardHeader'>
        <div className='eventCardTitle' onClick={() => setExpanded(!expanded)}>
          {event.name}
        </div>
        <div className='eventCardExpandIcon' onClick={() => setExpanded(!expanded)}>
          {expanded ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      <div className='eventCardActions'>
        <div className='eventCardEnabledSwitch'>
          <div className='eventCardEnabledSwitchTitle' onClick={() => setEnabled(!enabled)}>
            {enabled ? 'Enabled' : 'Disabled'}
          </div>
          <Switch size='small' checked={enabled} onChange={onChangeEnabled} />
        </div>
        <MdEdit className='editEventButton' onClick={openEditModal} />
      </div>
      <div className={`eventCardBody ${eventCardBodyClass}`}>
        {renderSection('Location', renderEventLocation())}
        {renderSection('Categories', renderEventCategories())}
        {renderSection('Scenes', renderEventScenes())}
        {renderSection('Start', renderEventTime(event.start))}
        {renderSection('End', renderEventTime(event.end))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    locations: locationsSelector(state),
    scenes: scenesSelector(state),
  };
};

export default connect(mapStateToProps)(EventCard);
