import { Middleware } from 'redux';
import { DevicesActionTypes, SYNC_DEVICES } from 'types';
import { setDevicesSyncing } from 'actions';
import * as schedulerService from 'services/scheduler.service';

export const devicesMiddleware: Middleware = store => next => async (action: DevicesActionTypes) => {
  switch (action.type) {
    case SYNC_DEVICES: {
      store.dispatch(setDevicesSyncing(true));
      await schedulerService.syncDevices();
      store.dispatch(setDevicesSyncing(false));

      return next(action);
    }
    default: {
      return next(action);
    }
  }
};
