import { Dictionary } from 'lodash';

export interface UsersState {
  [key: string]: Dictionary<string>;
}

export const INITIALIZE_USER = 'INITIALIZE_USER';

interface InitializeUser {
  type: typeof INITIALIZE_USER;
  email: string;
}

export type UsersActionTypes = InitializeUser;
