import { Middleware } from 'redux';
import { INITIALIZE_USER, UsersActionTypes, Location, User } from 'types';
import { setLocation, syncDevices } from 'actions';
import { db } from 'fire';

const connectionsCache: any = {};

export const usersMiddleware: Middleware = store => next => async (action: UsersActionTypes) => {
  switch (action.type) {
    case INITIALIZE_USER: {
      if (!connectionsCache[action.email]) {
        connectionsCache[action.email] = db
          .collection('users')
          .doc(action.email)
          .onSnapshot(doc => {
            const user = doc.data() as User;

            user.locations.forEach(locationId => {
              if (!connectionsCache[locationId]) {
                connectionsCache[locationId] = db
                  .collection('locations')
                  .doc(locationId)
                  .onSnapshot(doc => {
                    const location = doc.data();

                    if (location) {
                      store.dispatch(setLocation(location as Location));
                    }
                  });
              }
            });
          });
      }

      store.dispatch(syncDevices());

      return next(action);
    }
    default: {
      return next(action);
    }
  }
};
