import { AxiosResponse } from 'axios';
import AxiosWrapper from 'clients/axiosWrapper';
import { Dictionary } from 'lodash';
import { Event, Location, Scene } from 'types';

const axiosWrapper = new AxiosWrapper(process.env.REACT_APP_SCHEDULER_BASE_URL!);

export async function createUser(email: string): Promise<void> {
  await axiosWrapper.post(`users/create`, {
    email,
  });
}

export async function sendCommand(locationId: string, deviceId: string, level: number): Promise<void> {
  await axiosWrapper.post(`locations/${locationId}/runCommand`, {
    deviceId,
    level,
  });
}

export async function syncDevices(): Promise<void> {
  await axiosWrapper.post(`users/syncDevices`);
}

export async function runSceneById(locationId: string, sceneId: string): Promise<void> {
  await axiosWrapper.post(`locations/${locationId}/scenes/${sceneId}/run`);
}

export async function runScene(locationId: string, scene: Scene): Promise<void> {
  await axiosWrapper.post(`locations/${locationId}/scenes/run`, { scene });
}

export async function updateScene(locationId: string, scene: Scene): Promise<void> {
  await axiosWrapper.post(`locations/${locationId}/scenes`, { scene });
}

export async function deleteScene(locationId: string, sceneId: string): Promise<void> {
  await axiosWrapper.deleteRequest(`locations/${locationId}/scenes/${sceneId}`);
}

export async function updateEvent(locationId: string, event: Event): Promise<void> {
  await axiosWrapper.post(`locations/${locationId}/events`, { event });
}

export async function deleteEvent(locationId: string, eventId: string): Promise<void> {
  await axiosWrapper.deleteRequest(`locations/${locationId}/events/${eventId}`);
}

export function getHubAuthRedirectUrl(locationId: string, hub: string): Promise<AxiosResponse<string>> {
  return axiosWrapper.get(`locations/${locationId}/hubs/${hub}/auth`);
}

export async function disconnectFromHub(locationId: string, hub: string): Promise<void> {
  await axiosWrapper.deleteRequest(`locations/${locationId}/hubs/${hub}`);
}

export function updateLocation(location: Location): Promise<AxiosResponse<string>> {
  return axiosWrapper.post(`locations`, { location });
}

export async function deleteLocation(locationId: string): Promise<void> {
  await axiosWrapper.deleteRequest(`locations/${locationId}`);
}

export async function addUserToLocation(locationId: string, email: string): Promise<void> {
  await axiosWrapper.post(`locations/${locationId}/users/${email}`);
}

export async function removeUserFromLocation(locationId: string, email: string): Promise<void> {
  await axiosWrapper.deleteRequest(`locations/${locationId}/users/${email}`);
}

export async function getScheduledScene(locationId: string, date: Date): Promise<Dictionary<number>> {
  return (await axiosWrapper.get(`scheduler/scene?locationId=${locationId}&date=${date}`)).data as Dictionary<number>;
}
