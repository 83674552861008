import { LocationsState, SET_LOCATION, LocationsActionTypes, DELETE_LOCATION } from 'types';

export default function locationsReducer(state: LocationsState = {}, action: LocationsActionTypes): LocationsState {
  switch (action.type) {
    case SET_LOCATION: {
      return {
        ...state,
        [action.location.id]: action.location,
      };
    }
    case DELETE_LOCATION: {
      delete state[action.locationId];

      return { ...state };
    }
    default: {
      return state;
    }
  }
}
