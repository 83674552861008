import 'components/Scenes/Scenes.scss';
import { Select, Input, TimePicker } from 'antd';
import { SCHEDULED_TIME_TYPE, EVENT_TIME_TYPE, ScheduledTime, EventTime, EventCategory } from 'types';
import { useEffect, useState } from 'react';
import { Dictionary } from 'lodash';
import { getHours, getMinutes } from 'date-fns';
import moment from 'moment';

const { Option } = Select;

interface SetEventTimeProps {
  title: string;
  onChange: (eventTime: EventTime | ScheduledTime) => void;
  defaultValue: EventTime | ScheduledTime;
}

const eventTypeOptions: Dictionary<string> = {
  [SCHEDULED_TIME_TYPE]: 'Time',
  [EventCategory.SUNRISE]: 'Sunrise',
  [EventCategory.SUNSET]: 'Sunset',
  [EventCategory.CANDLE_LIGHTING]: 'Candle Lighting',
  [EventCategory.HAVDALAH]: 'Havdalah',
};

const SetEventTime: React.FC<SetEventTimeProps> = props => {
  const { onChange, defaultValue, title } = props;
  const defaultEventType = defaultValue.type === EVENT_TIME_TYPE ? defaultValue.eventCategory : SCHEDULED_TIME_TYPE;

  const [selectedType, setSelectedType] = useState(defaultEventType);
  const [eventHour, setEventHour] = useState((defaultValue as ScheduledTime).hour || 0);
  const [eventMinute, setEventMinute] = useState((defaultValue as ScheduledTime).minute || 0);
  const [eventCategory, setEventCategory] = useState(
    (defaultValue as EventTime).eventCategory || EventCategory.CANDLE_LIGHTING,
  );
  const [eventOffset, setEventOffset] = useState((defaultValue as EventTime).offset || 0);

  useEffect(() => {
    updateEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventOffset, eventHour, eventMinute, eventCategory, selectedType]);

  const updateEvent = () => {
    if (selectedType === SCHEDULED_TIME_TYPE) {
      const event: ScheduledTime = {
        type: SCHEDULED_TIME_TYPE,
        hour: eventHour,
        minute: eventMinute,
      };

      onChange(event);
    } else {
      const event: EventTime = {
        type: EVENT_TIME_TYPE,
        eventCategory,
        offset: eventOffset,
      };

      onChange(event);
    }
  };

  const onEventTypeChange = (eventType: string) => {
    setSelectedType(eventType);

    if (eventType !== SCHEDULED_TIME_TYPE) {
      setEventCategory(eventType as EventCategory);
    }
  };

  const onEventTimeChange = (date: Date) => {
    setEventHour(getHours(date));
    setEventMinute(getMinutes(date));
  };

  const onEventOffsetChange = (value: number) => {
    setEventOffset(value || 0);
  };

  const renderTimeSelect = () => {
    if (selectedType !== SCHEDULED_TIME_TYPE) {
      return null;
    }

    const defaultDate = moment();
    defaultDate.hour(eventHour).minute(eventMinute);

    return (
      <div className='startFormTimePicker'>
        <TimePicker
          format='h:mm a'
          size='small'
          defaultValue={defaultDate}
          onChange={e => {
            if (e) {
              onEventTimeChange(e.toDate());
            }
          }}
        />
      </div>
    );
  };

  const renderEventSelect = () => {
    if (selectedType === SCHEDULED_TIME_TYPE) {
      return null;
    }

    return (
      <>
        <div>(+/-)</div>
        <Input
          className='editEventTimeOffsetInput'
          type='number'
          size='small'
          style={{ width: '40px' }}
          defaultValue={eventOffset}
          onChange={e => onEventOffsetChange(parseInt(e.target.value))}
        />
        <div>Minutes</div>
      </>
    );
  };

  return (
    <div className='editEventTimeWrapper'>
      <div className='editEventTimeTitle'>{title}</div>
      <div className='editEventTimeForm'>
        <Select
          defaultValue={selectedType}
          style={{ width: '140px', marginRight: '10px' }}
          size='small'
          onChange={onEventTypeChange}
        >
          {Object.keys(eventTypeOptions).map((key, index) => (
            <Option key={index} value={key}>
              {eventTypeOptions[key]}
            </Option>
          ))}
        </Select>
        {renderTimeSelect()}
        {renderEventSelect()}
      </div>
    </div>
  );
};

export default SetEventTime;
