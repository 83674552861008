import { createSelector } from 'reselect';
import { RootState } from 'reducers/root.reducer';
import { Event, LocationsState } from 'types';

const getLocations = (state: RootState): LocationsState => state.locations;
const getEventsFilter = (state: RootState): string => state.filters.events;

export const eventsSelector = createSelector<RootState, LocationsState, string, Event[]>(
  [getLocations, getEventsFilter],
  (locations, filter) => {
    return Object.values(locations).reduce<Event[]>((events, location) => {
      const mappedEvents = Object.values(location.events)
        .filter(event => event.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
        .sort((a, b) => (a.name < b.name ? -1 : 1));

      return events.concat(...mappedEvents);
    }, []);
  },
);
