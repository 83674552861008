import { Middleware } from 'redux';
import { LocationsActionTypes } from 'types';

export const scenesMiddleware: Middleware = () => next => async (action: LocationsActionTypes) => {
  switch (action.type) {
    default: {
      return next(action);
    }
  }
};
