import { setCurrentTab } from 'actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { Middleware } from 'redux';
import { Tabs } from 'types';

export const routerMiddleware: Middleware = store => next => action => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const { pathname }: { pathname: string } = action.payload.location;
      const match = pathname.match(/^(\/views\/(\w*)\/?)$/);

      if (match && match.length === 3 && Object.values(Tabs).includes(match[2] as Tabs)) {
        store.dispatch(setCurrentTab(match[2]));
      } else {
        store.dispatch(setCurrentTab(''));
      }

      return next(action);
    }
    default: {
      return next(action);
    }
  }
};
