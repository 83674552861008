import 'components/Header/Header.scss';
import React from 'react';
import { MdMenu } from 'react-icons/md';
import { Dropdown, Menu } from 'antd';
import { FiLogOut } from 'react-icons/fi';
import { auth } from 'fire';

const Header: React.FC = () => {
  const logout = async () => {
    await auth.signOut();
  };

  const menu = (
    <Menu>
      <Menu.Item className='pageHeaderMenuItem' onClick={logout}>
        <FiLogOut />
        <div className='pageHeaderMenuItemText'>Logout</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='pageHeader'>
      <div className='pageHeaderTitle'>Ready For Shabbat</div>
      <div className='pageHeaderMenu'>
        <Dropdown overlay={menu} trigger={['click']}>
          <div className='pageHeaderMenuIcon'>
            <MdMenu />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
