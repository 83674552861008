import { Location } from 'types/models/location.types';

export interface LocationsState {
  [key: string]: Location;
}

export const SET_LOCATION = 'SET_LOCATION';
export const SEND_COMMAND = 'SEND_COMMAND';
export const RUN_SCENE = 'RUN_SCENE';
export const DELETE_LOCATION = 'DELETE_LOCATION';

interface SetLocation {
  type: typeof SET_LOCATION;
  location: Location;
}

interface DeleteLocation {
  type: typeof DELETE_LOCATION;
  locationId: string;
}

interface SendCommand {
  type: typeof SEND_COMMAND;
  locationId: string;
  deviceId: string;
  level: number;
}

interface RunScene {
  type: typeof RUN_SCENE;
  locationId: string;
  sceneId: string;
}

export type LocationsActionTypes = SetLocation | SendCommand | RunScene | DeleteLocation;
