import * as schedulerClient from 'clients/scheduler.client';
import { Dictionary } from 'lodash';
import { Event, Hubs, Location, Scene } from 'types';

export async function createUser(email: string): Promise<void> {
  await schedulerClient.createUser(email);
}

export async function sendCommand(locationId: string, deviceId: string, level: number): Promise<void> {
  await schedulerClient.sendCommand(locationId, deviceId, level);
}

export async function syncDevices(): Promise<void> {
  await schedulerClient.syncDevices();
}

export async function runSceneById(locationId: string, sceneId: string): Promise<void> {
  await schedulerClient.runSceneById(locationId, sceneId);
}

export async function runScene(locationId: string, scene: Scene): Promise<void> {
  await schedulerClient.runScene(locationId, scene);
}

export async function updateScene(locationId: string, scene: Scene): Promise<void> {
  await schedulerClient.updateScene(locationId, scene);
}

export async function deleteScene(locationId: string, sceneId: string): Promise<void> {
  await schedulerClient.deleteScene(locationId, sceneId);
}

export async function updateEvent(locationId: string, event: Event): Promise<void> {
  await schedulerClient.updateEvent(locationId, event);
}

export async function deleteEvent(locationId: string, eventId: string): Promise<void> {
  await schedulerClient.deleteEvent(locationId, eventId);
}

export async function connectToHub(locationId: string, hub: string): Promise<void> {
  const redirectUrl = (await schedulerClient.getHubAuthRedirectUrl(locationId, hub)).data;

  window.location.href = redirectUrl;
}

export async function disconnectFromHub(locationId: string, hub: Hubs): Promise<void> {
  await schedulerClient.disconnectFromHub(locationId, hub);
}

export async function updateLocation(location: Location): Promise<string> {
  return (await schedulerClient.updateLocation(location)).data;
}

export async function deleteLocation(locationId: string): Promise<void> {
  await schedulerClient.deleteLocation(locationId);
}

export async function addUserToLocation(locationId: string, email: string): Promise<void> {
  await schedulerClient.addUserToLocation(locationId, email);
}

export async function removeUserFromLocation(locationId: string, email: string): Promise<void> {
  await schedulerClient.removeUserFromLocation(locationId, email);
}

export function getScheduledScene(locationId: string, date: Date): Promise<Dictionary<number>> {
  return schedulerClient.getScheduledScene(locationId, date);
}
