import { createSelector } from 'reselect';
import { RootState } from 'reducers/root.reducer';
import { Device } from 'types';
import { devicesSelector } from 'selectors/devices.selector';

const getDevices = (state: RootState): Device[] => devicesSelector(state);
const getDevicesFilter = (state: RootState): string => state.filters.devices;

export const filteredDevicesSelector = createSelector<RootState, Device[], string, Device[]>(
  [getDevices, getDevicesFilter],
  (devices, filter) => {
    return devices.filter(device => device.deviceName.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
  },
);
