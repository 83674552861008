import 'components/Locations/Locations.scss';
import React, { useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RootState } from 'reducers/root.reducer';
import { currUserSelector, filteredLocationsSelector, filtersSelector } from 'selectors';
import { Location } from 'types';
import { Input, Button, Modal } from 'antd';
import { setLocationsFilter } from 'actions';
import LocationCard from 'components/Locations/LocationCard';
import EditLocation from 'components/Locations/EditLocation';

const { Search } = Input;

interface LocationsProps extends DispatchProp {
  locations: Location[];
  filter: string;
  currUser: string;
}

const Locations: React.FC<LocationsProps> = props => {
  const { dispatch, locations, filter, currUser } = props;

  const defaultModalLocation = (): Location => ({
    id: '',
    name: '',
    zipcode: '',
    owner: currUser,
    users: [currUser],
    hubs: [],
    devices: [],
    events: {},
    scenes: {},
  });

  const [showModal, setShowModal] = useState(false);
  const [modalLocation, setModalLocation] = useState(defaultModalLocation());

  const onSearch = (e: any) => {
    dispatch(setLocationsFilter(e.target.value));
  };

  const handleModalClose = () => {
    setModalLocation(defaultModalLocation());
    setShowModal(false);
  };

  const openEditModal = (location: Location) => {
    setModalLocation(location);
    setShowModal(true);
  };

  const renderLocations = () => {
    return locations.map((location, index) => {
      return <LocationCard key={index} location={location} openEditModal={() => openEditModal(location)} />;
    });
  };

  return (
    <>
      <div className='tabTitle'>
        <div className='locationsTabTitleText'>Locations</div>
        <div className='locationsFilter'>
          <Search allowClear placeholder='Filter locations...' onChange={onSearch} value={filter} />
          <Button className='newLocationButton' onClick={() => setShowModal(true)} type='primary'>
            New
          </Button>
          <Modal
            visible={showModal}
            onCancel={handleModalClose}
            title={modalLocation.id ? 'Edit Location' : 'New Location'}
            footer={null}
            destroyOnClose={true}
            centered
          >
            <EditLocation location={modalLocation} onClose={handleModalClose} />
          </Modal>
        </div>
      </div>
      <div className='tabBody'>{renderLocations()}</div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    locations: filteredLocationsSelector(state),
    filter: filtersSelector(state).locations,
    currUser: currUserSelector(state),
  };
};

export default connect(mapStateToProps)(Locations);
