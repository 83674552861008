import 'components/FooterTabs/FooterTabs.scss';
import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteChildrenProps, withRouter } from 'react-router';
import { Tabs } from 'types';
import { IoMdPower } from 'react-icons/io';
import { WiHorizon } from 'react-icons/wi';
import { GoCalendar } from 'react-icons/go';
import { MdEvent, MdLocationOn } from 'react-icons/md';
import { RootState } from 'reducers/root.reducer';
import { currTabSelector } from 'selectors';

const tabs = [
  {
    tab: Tabs.DEVICES,
    icon: <IoMdPower />,
    text: 'Devices',
  },
  {
    tab: Tabs.SCENES,
    icon: <WiHorizon />,
    text: 'Scenes',
  },
  {
    tab: Tabs.EVENTS,
    icon: <MdEvent />,
    text: 'Events',
  },
  {
    tab: Tabs.SCHEDULE,
    icon: <GoCalendar />,
    text: 'Schedule',
  },
  {
    tab: Tabs.LOCATIONS,
    icon: <MdLocationOn />,
    text: 'Locations',
  },
];

interface FooterTabsProps extends DispatchProp, RouteChildrenProps {
  currTab: string;
}

const FooterTabs: React.FC<FooterTabsProps> = props => {
  const { history, currTab } = props;

  const onTabClick = (tab: Tabs) => {
    if (tab !== currTab) {
      history.push(`/views/${tab}`);
    }
  };

  return (
    <div className='footerTabs'>
      {tabs.map(tab => {
        const className = tab.tab === currTab ? 'footerTab selected' : 'footerTab';

        return (
          <div className={className} key={tab.tab} onClick={() => onTabClick(tab.tab)}>
            <div className='footerTabIcon'>{tab.icon}</div>
            <div>{tab.text}</div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    currTab: currTabSelector(state),
  };
};

export default withRouter(connect(mapStateToProps)(FooterTabs));
