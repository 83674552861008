import { createSelector } from 'reselect';
import { RootState } from 'reducers/root.reducer';
import { Scene } from 'types';
import { scenesSelector } from 'selectors/scenes.selector';

const getScenes = (state: RootState): Scene[] => scenesSelector(state);
const getScenesFilter = (state: RootState): string => state.filters.scenes;

export const filteredScenesSelector = createSelector<RootState, Scene[], string, Scene[]>(
  [getScenes, getScenesFilter],
  (scenes, filter) => {
    return scenes.filter(scene => scene.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
  },
);
