import 'components/Devices/Devices.scss';
import { Slider, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Device } from 'types';
import { sendCommand } from 'actions';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

interface DeviceCardProps extends DispatchProp {
  device: Device;
}

const DeviceCard: React.FC<DeviceCardProps> = props => {
  const { device, dispatch } = props;
  const { deviceId, locationId } = device;

  const [expanded, setExpanded] = useState(false);
  const [level, setLevel] = useState(device.level);

  useEffect(() => {
    setLevel(device.level);
  }, [device]);

  const handleSwitchChange = (checked: boolean) => {
    const newLevel = checked ? 100 : 0;

    setLevel(newLevel);
    dispatch(sendCommand(locationId, deviceId, newLevel));
  };

  const handleSliderChange = (value: number) => {
    setLevel(value);
  };

  const handleSliderRelease = (value: number) => {
    dispatch(sendCommand(locationId, deviceId, value));
  };

  const deviceCardBodyClass = expanded ? 'visible' : 'hidden';

  return (
    <div className='deviceCard'>
      <div className='deviceCardHeader'>
        <div className='deviceCardTitle' onClick={() => setExpanded(!expanded)}>
          {device.deviceName}
        </div>
        <div className='deviceCardStatus'>
          <div className='deviceLevel'>{level}%</div>
          <Switch size='small' checked={level > 0} onChange={handleSwitchChange} />
          <div className='deviceCardExpandIcon' onClick={() => setExpanded(!expanded)}>
            {expanded ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>
      </div>
      <div className={`deviceCardBody ${deviceCardBodyClass}`}>
        <Slider
          value={level}
          onChange={handleSliderChange}
          onAfterChange={handleSliderRelease}
          tooltipVisible={false}
        />
      </div>
    </div>
  );
};

export default connect()(DeviceCard);
