import 'components/Locations/Locations.scss';
import React, { useState } from 'react';
import { Button, Modal, Input } from 'antd';
import { FaTrashAlt, FaUndo } from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import { Location } from 'types';
import * as schedulerService from 'services/scheduler.service';
import { deleteLocation } from 'actions';
import { connect, DispatchProp } from 'react-redux';

interface EditLocationProps extends DispatchProp {
  location: Location;
  onClose: () => void;
}

const EditLocation: React.FC<EditLocationProps> = props => {
  const { dispatch, onClose, location } = props;

  const [editedLocation, setEditedLocation] = useState<Location>(location);
  const [resetCount, setResetCount] = useState(0);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const onNameUpdated = (e: any) => {
    setEditedLocation({
      ...editedLocation,
      name: e.target.value,
    });
  };

  const onZipcodeUpdated = (e: any) => {
    setEditedLocation({
      ...editedLocation,
      zipcode: e.target.value,
    });
  };

  const resetState = async (): Promise<void> => {
    setResetCount(resetCount + 1);
    setEditedLocation(location);
    setSaving(false);
    setDeleting(false);
  };

  const handleModalClose = async () => {
    onClose();
  };

  const resetEdits = () => {
    Modal.confirm({
      title: 'Reset Changes',
      content: 'Are you sure you want to reset your changes?',
      maskClosable: true,
      onOk: resetState,
    });
  };

  const saveEdits = async () => {
    setSaving(true);

    try {
      await schedulerService.updateLocation(editedLocation);
    } catch (err) {
      Modal.info({
        title: 'Could not save location',
        content: 'There was an error trying to save this location. Please try again.',
      });
    }

    setSaving(false);
    handleModalClose();
  };

  const handleDeleteLocation = () => {
    Modal.confirm({
      title: 'Delete Location',
      content: 'Are you sure you want to delete this location?',
      maskClosable: true,
      icon: (
        <GoAlert style={{ color: 'red', fontSize: '22px', float: 'left', marginRight: '16px', marginBottom: '20px' }} />
      ),
      okText: 'Delete',
      onOk: async () => {
        setDeleting(true);

        try {
          await schedulerService.deleteLocation(editedLocation.id);
          dispatch(deleteLocation(editedLocation.id));
          handleModalClose();
        } catch (err) {
          Modal.info({
            title: 'Could not delete location',
            content: 'There was an error trying to delete this location. Please try again.',
          });
        }

        setDeleting(false);
      },
      okButtonProps: {
        type: 'primary',
        danger: true,
        loading: deleting,
      },
    });
  };

  const canSave = editedLocation.name.length > 0 && editedLocation.zipcode.length > 0;

  return (
    <>
      <div className='editLocationNameWrapper'>
        <div className='editLocationTitle'>Name</div>
        <Input className='editLocationName' value={editedLocation.name} onChange={onNameUpdated} size='small' />
      </div>
      <div className='editLocationNameWrapper'>
        <div className='editLocationTitle'>Zipcode</div>
        <Input className='editLocationName' value={editedLocation.zipcode} onChange={onZipcodeUpdated} size='small' />
      </div>
      <div className='editLocationFooterButtons'>
        <div className='leftButtons'>
          {editedLocation.id && (
            <div className='editLocationReset' onClick={resetEdits}>
              <FaUndo />
            </div>
          )}
          {editedLocation.id && (
            <div className='editLocationDelete' onClick={handleDeleteLocation}>
              <FaTrashAlt />
            </div>
          )}
        </div>
        <div className='rightButtons'>
          <Button className='saveButton' type='primary' onClick={saveEdits} disabled={!canSave} loading={saving}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default connect()(EditLocation);
