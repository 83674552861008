export const SET_DEVICES_FILTER = 'SET_DEVICES_FILTER';
export const SET_SCENES_FILTER = 'SET_SCENES_FILTER';

export const SET_EVENTS_FILTER = 'SET_EVENTS_FILTER';
export const SET_LOCATIONS_FILTER = 'SET_LOCATIONS_FILTER';

interface SetDevicesFilter {
  type: typeof SET_DEVICES_FILTER;
  filter: string;
}

interface SetScenesFilter {
  type: typeof SET_SCENES_FILTER;
  filter: string;
}

interface SetEventsFilter {
  type: typeof SET_EVENTS_FILTER;
  filter: string;
}

interface SetLocationsFilter {
  type: typeof SET_LOCATIONS_FILTER;
  filter: string;
}

export type FiltersActionTypes = SetDevicesFilter | SetScenesFilter | SetEventsFilter | SetLocationsFilter;
