import 'components/Scenes/Scenes.scss';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Input, Tabs, Checkbox, Select, Switch } from 'antd';
import { Scene, Device, Location } from 'types';
import { Dictionary } from 'lodash';
import { RootState } from 'reducers/root.reducer';
import { devicesSelector, locationsSelector } from 'selectors';
import EditSceneDevice from 'components/Scenes/EditSceneDevice';
import { FaTrashAlt, FaUndo } from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import * as schedulerService from 'services/scheduler.service';

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

interface IScene {
  id: string;
  name: string;
  locationId: string;
}

interface EditSceneProps {
  scene: IScene;
  sceneDevices: Dictionary<number>;
  devices: Device[];
  locations: Location[];
  onClose: () => void;
}

let cachedScene: Scene;

const EditScene: React.FC<EditSceneProps> = props => {
  const { scene, sceneDevices, devices, locations, onClose } = props;

  const [allDevicesFilter, setAllDevicesFilter] = useState('');
  const [selectedDevicesFilter, setSelectedDevicesFilter] = useState('');
  const [selectedDevices, setSelectedDevices] = useState<Dictionary<number>>(sceneDevices);
  const [editedScene, setEditedScene] = useState<IScene>(scene);
  const [resetCount, setResetCount] = useState(0);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);

  const resetState = async (): Promise<void> => {
    if (previewMode) {
      await togglePreviewMode(false);
    }

    setResetCount(resetCount + 1);
    setAllDevicesFilter('');
    setSelectedDevicesFilter('');
    setEditedScene(scene);
    setSelectedDevices(sceneDevices);
    setSaving(false);
    setDeleting(false);
  };

  const handleModalClose = async () => {
    if (previewMode) {
      await togglePreviewMode(false);
    }

    onClose();
  };

  const onNameUpdated = (e: any) => {
    setEditedScene({
      ...editedScene,
      name: e.target.value,
    });
  };

  const onSelectLocation = (locationId: string) => {
    if (locationId !== editedScene.locationId && previewMode) {
      togglePreviewMode(false);
    }

    setEditedScene({
      ...editedScene,
      locationId,
    });
  };

  const onSelectDevice = async (checked: boolean, deviceId: string) => {
    const copiedState = { ...selectedDevices };

    if (checked) {
      copiedState[deviceId] = 100;

      if (previewMode) {
        await schedulerService.sendCommand(editedScene.locationId, deviceId, 100);
      }
    } else {
      delete copiedState[deviceId];

      if (previewMode) {
        const level = cachedScene.devices.find(d => d.deviceId === deviceId)!.level;
        await schedulerService.sendCommand(editedScene.locationId, deviceId, level);
      }
    }

    setSelectedDevices(copiedState);
  };

  const resetEdits = () => {
    Modal.confirm({
      title: 'Reset Changes',
      content: 'Are you sure you want to reset your changes?',
      maskClosable: true,
      onOk: resetState,
    });
  };

  const handleDeleteScene = () => {
    Modal.confirm({
      title: 'Delete Scene',
      content: 'Are you sure you want to delete this scene?',
      maskClosable: true,
      icon: (
        <GoAlert style={{ color: 'red', fontSize: '22px', float: 'left', marginRight: '16px', marginBottom: '20px' }} />
      ),
      okText: 'Delete',
      onOk: async () => {
        try {
          setDeleting(true);
          await schedulerService.deleteScene(editedScene.locationId, editedScene.id);
          handleModalClose();
        } catch (err) {
          Modal.info({
            title: 'Could not delete scene',
            content: 'There was an error trying to delete this scene. Please try again.',
          });
        }

        setDeleting(false);
      },
      okButtonProps: {
        type: 'primary',
        danger: true,
        loading: deleting,
      },
    });
  };

  const convertStateToScene = (): Scene => {
    const copiedState = { ...editedScene } as Scene;

    copiedState.devices = Object.keys(selectedDevices)
      .map(deviceId => {
        const device = { ...devices.find(d => d.deviceId === deviceId)! };
        device.level = selectedDevices[deviceId];

        return device;
      })
      .sort((a, b) => (a.deviceName < b.deviceName ? -1 : 1));

    return copiedState;
  };

  const togglePreviewMode = async (checked: boolean) => {
    if (!editedScene.locationId) {
      return;
    }

    setPreviewMode(checked);

    if (checked) {
      cachedScene = {
        id: editedScene.id,
        name: editedScene.name,
        locationId: editedScene.locationId,
        devices: devices.filter(device => device.locationId === editedScene.locationId).map(device => ({ ...device })),
      };

      await schedulerService.runScene(editedScene.locationId, convertStateToScene());
    } else {
      await schedulerService.runScene(cachedScene.locationId, cachedScene);

      cachedScene = {} as Scene;
    }
  };

  const saveEdits = async () => {
    try {
      setSaving(true);
      await schedulerService.updateScene(editedScene.locationId, convertStateToScene());
      handleModalClose();
    } catch (err) {
      Modal.info({
        title: 'Could not save scene',
        content: 'There was an error trying to save this scene. Please try again.',
      });
    }

    setSaving(false);
  };

  const updateDeviceLevel = (device: Device, level: number) => {
    setSelectedDevices({
      ...selectedDevices,
      [device.deviceId]: level,
    });

    if (previewMode) {
      schedulerService.sendCommand(editedScene.locationId, device.deviceId, level);
    }
  };

  const renderSelectLocation = () => {
    return (
      <div className='editSceneSelectLocationWrapper'>
        <div className='editSceneSelectLocationTitle'>Location</div>
        <Select
          key={`selectLocation-${resetCount}`}
          className='editSceneSelectLocation'
          defaultValue={editedScene.locationId}
          onChange={onSelectLocation}
        >
          {locations.map(location => {
            return (
              <Option key={location.id} value={location.id}>
                {location.name}
              </Option>
            );
          })}
        </Select>
      </div>
    );
  };

  const renderAllDevices = () => {
    return devices
      .filter(device => {
        return (
          device.locationId === editedScene.locationId &&
          device.deviceName.toLocaleLowerCase().includes(allDevicesFilter.toLocaleLowerCase())
        );
      })
      .map((device, index) => {
        const checked = Object.keys(selectedDevices).includes(device.deviceId);

        return (
          <div className='allDevicesItem' key={index}>
            <Checkbox checked={checked} onChange={e => onSelectDevice(e.target.checked, device.deviceId)}>
              {device.deviceName}
            </Checkbox>
            {checked && <div className='allDevicesItemLevel'>{selectedDevices[device.deviceId]}%</div>}
          </div>
        );
      });
  };

  const renderSelectedDevices = () => {
    const mappedDevices = devices.filter(
      device =>
        Object.keys(selectedDevices).includes(device.deviceId) &&
        device.deviceName.toLocaleLowerCase().includes(selectedDevicesFilter.toLocaleLowerCase()),
    );

    return mappedDevices.map(device => {
      return (
        <EditSceneDevice
          key={`${device.deviceId}-${resetCount}`}
          device={device}
          defaultLevel={selectedDevices[device.deviceId]}
          onChangeLevel={updateDeviceLevel}
          onRemoveDevice={() => onSelectDevice(false, device.deviceId)}
        />
      );
    });
  };

  const canSave = editedScene.name.length > 0 && editedScene.locationId;

  return (
    <>
      <div className='editSceneNameWrapper'>
        <div className='editSceneTitle'>Name</div>
        <Input className='editSceneName' value={editedScene.name} onChange={onNameUpdated} />
      </div>
      {renderSelectLocation()}
      <div className='previewMode'>
        <div className='previewModeTitle' onClick={() => togglePreviewMode(!previewMode)}>
          Preview Mode
        </div>
        <Switch size='small' checked={previewMode} onChange={togglePreviewMode} disabled={!editedScene.locationId} />
      </div>
      <Tabs centered={true}>
        <TabPane tab='All Devices' key='All Devices'>
          <Search
            className='allDevicesFilter'
            placeholder='Filter devices...'
            onChange={e => setAllDevicesFilter(e.target.value)}
            allowClear={true}
          />
          <div className='allDevicesWrapper'>{renderAllDevices()}</div>
        </TabPane>
        <TabPane tab={`Selected Devices (${Object.keys(selectedDevices).length})`} key='Selected Devices'>
          <Search
            className='selectedDevicesFilter'
            placeholder='Filter devices...'
            onChange={e => setSelectedDevicesFilter(e.target.value)}
            allowClear={true}
          />
          <div className='selectedDevicesWrapper'>{renderSelectedDevices()}</div>
        </TabPane>
      </Tabs>
      <div className='editSceneFooterButtons'>
        <div className='leftButtons'>
          {editedScene.id && (
            <div className='editSceneReset' onClick={resetEdits}>
              <FaUndo />
            </div>
          )}
          {editedScene.id && (
            <div className='editSceneDelete' onClick={handleDeleteScene}>
              <FaTrashAlt />
            </div>
          )}
        </div>
        <div className='rightButtons'>
          <Button className='saveButton' type='primary' onClick={saveEdits} disabled={!canSave} loading={saving}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    devices: devicesSelector(state),
    locations: locationsSelector(state),
  };
};

export default connect(mapStateToProps)(EditScene);
