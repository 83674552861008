import { SET_DEVICES_SYNCING } from 'types';

export default function devicesSyncingReducer(state = false, action: any): boolean {
  switch (action.type) {
    case SET_DEVICES_SYNCING: {
      return action.syncing;
    }
    default: {
      return state;
    }
  }
}
