import 'components/Devices/Devices.scss';
import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RootState } from 'reducers/root.reducer';
import { devicesSyncingSelector, filteredDevicesSelector, filtersSelector } from 'selectors';
import { Device } from 'types';
import Search from 'antd/lib/input/Search';
import { Button } from 'antd';
import { setDevicesFilter, syncDevices } from 'actions';
import DeviceCard from 'components/Devices/DeviceCard';

interface DevicesProps extends DispatchProp {
  devices: Device[];
  filter: string;
  devicesSyncing: boolean;
}

const Devices: React.FC<DevicesProps> = props => {
  const { devices, dispatch, filter, devicesSyncing } = props;

  const onSearch = (e: any) => {
    dispatch(setDevicesFilter(e.target.value));
  };

  const onSyncDevices = () => {
    dispatch(syncDevices());
  };

  return (
    <>
      <div className='tabTitle'>
        <div className='devicesTabTitleText'>Devices</div>
        <div className='devicesActions'>
          <Search allowClear placeholder='Filter devices...' onChange={onSearch} value={filter} />
          <Button className='devicesSyncButton' onClick={onSyncDevices} type='primary' loading={devicesSyncing}>
            {devicesSyncing ? 'Syncing' : 'Sync'}
          </Button>
        </div>
      </div>
      <div className='tabBody'>
        {devices
          .sort((a, b) => (a.deviceName < b.deviceName ? -1 : 1))
          .map((device, index) => (
            <DeviceCard device={device} key={index} />
          ))}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    devices: filteredDevicesSelector(state),
    filter: filtersSelector(state).devices,
    devicesSyncing: devicesSyncingSelector(state),
  };
};

export default connect(mapStateToProps)(Devices);
