import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import devicesSyncingReducer from 'reducers/devicesSyncing.reducer';
import locationsReducer from 'reducers/locations.reducer';
import currTabReducer from 'reducers/currTab.reducer';
import filtersReducer from 'reducers/filters.reducer';
import currentUserReducer from 'reducers/currUser.reducer';

export const history = createBrowserHistory();

export const rootReducer = combineReducers({
  currTab: currTabReducer,
  currUser: currentUserReducer,
  devicesSyncing: devicesSyncingReducer,
  filters: filtersReducer,
  locations: locationsReducer,
  router: connectRouter(history),
});

export type RootState = ReturnType<typeof rootReducer>;
