import 'components/Locations/Locations.scss';
import React from 'react';
import * as schedulerService from 'services/scheduler.service';
import { Modal } from 'antd';
import InsteonLogo from 'images/InsteonLogo.png';

interface ConnectToHubModalProps {
  locationId: string;
  showModal: boolean;
  onClose: () => void;
}

const ConnectToHubModal: React.FC<ConnectToHubModalProps> = props => {
  const { locationId, showModal, onClose } = props;

  const connectToInsteon = async () => {
    await schedulerService.connectToHub(locationId, 'insteon');
  };

  if (!locationId) {
    return null;
  }

  return (
    <Modal title='Connect to Hub' visible={showModal} onCancel={onClose} footer={null}>
      <div className='connectToHubModalButton' onClick={connectToInsteon}>
        <img src={InsteonLogo} alt='' />
      </div>
    </Modal>
  );
};

export default ConnectToHubModal;
