import { INITIALIZE_USER } from 'types';

export default function currentUserReducer(state = '', action: any): string {
  switch (action.type) {
    case INITIALIZE_USER: {
      return action.email;
    }
    default: {
      return state;
    }
  }
}
