import { DayCategory, EventCategory } from 'types';
import { Dictionary } from 'lodash';

export enum Hubs {
  INSTEON = 'insteon',
}

export interface Location {
  id: string;
  name: string;
  owner: string;
  users: string[];
  zipcode: string;
  hubs: Hubs[];
  devices: Device[];
  scenes: Dictionary<Scene>;
  events: Dictionary<Event>;
}

export interface HubAuth {
  accessToken: string;
  refreshToken: string;
  expiration: number;
  apiKey: string;
}

export interface Device {
  deviceId: string;
  deviceName: string;
  hub: Hubs;
  locationId: string;
  level: number;
}

export interface Scene {
  id: string;
  name: string;
  locationId: string;
  devices: Device[];
}

export interface Event {
  id: string;
  name: string;
  locationId: string;
  enabled: boolean;
  categories: DayCategory[];
  start: EventTime | ScheduledTime;
  end: EventTime | ScheduledTime;
  scenes: string[];
}

export const EVENT_TIME_TYPE = 'EVENT_TIME_TYPE';
export const SCHEDULED_TIME_TYPE = 'SCHEDULED_TIME_TYPE';

export interface EventTime {
  type: typeof EVENT_TIME_TYPE;
  eventCategory: EventCategory;
  offset?: number;
}

export interface ScheduledTime {
  type: typeof SCHEDULED_TIME_TYPE;
  hour: number;
  minute: number;
}
