import { createSelector } from 'reselect';
import { RootState } from 'reducers/root.reducer';
import { Location } from 'types';
import { locationsSelector } from 'selectors/locations.selector';

const getLocations = (state: RootState): Location[] => locationsSelector(state);
const getLocationsFilter = (state: RootState): string => state.filters.locations;

export const filteredLocationsSelector = createSelector<RootState, Location[], string, Location[]>(
  [getLocations, getLocationsFilter],
  (locations, filter) => {
    return locations.filter(location => location.name.includes(filter));
  },
);
