import { Dictionary } from 'lodash';
import {
  FiltersActionTypes,
  SET_DEVICES_FILTER,
  SET_EVENTS_FILTER,
  SET_SCENES_FILTER,
  SET_LOCATIONS_FILTER,
} from 'types';

const initialState = {
  devices: '',
  scenes: '',
  events: '',
  locations: '',
};

export default function filtersReducer(
  state: Dictionary<string> = initialState,
  action: FiltersActionTypes,
): Dictionary<string> {
  switch (action.type) {
    case SET_DEVICES_FILTER: {
      return {
        ...state,
        devices: action.filter,
      };
    }
    case SET_SCENES_FILTER: {
      return {
        ...state,
        scenes: action.filter,
      };
    }
    case SET_EVENTS_FILTER: {
      return {
        ...state,
        events: action.filter,
      };
    }
    case SET_LOCATIONS_FILTER: {
      return {
        ...state,
        locations: action.filter,
      };
    }
    default: {
      return state;
    }
  }
}
