import 'components/Scenes/Scenes.scss';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Input, Select, Tag } from 'antd';
import { Location, Event, Scene, DayCategory, EventTime, ScheduledTime } from 'types';
import { RootState } from 'reducers/root.reducer';
import { locationsSelector, scenesSelector } from 'selectors';
import { FaTrashAlt, FaUndo } from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import * as schedulerService from 'services/scheduler.service';
import SetEventTime from 'components/Events/SetEventTime';

const { Option } = Select;

interface EditEventProps {
  event: Event;
  locations: Location[];
  scenes: Scene[];
  onClose: () => void;
}

const EditEvent: React.FC<EditEventProps> = props => {
  const { locations, onClose, event, scenes } = props;

  const [editedEvent, setEditedEvent] = useState<Event>(event);
  const [resetCount, setResetCount] = useState(0);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const resetState = async (): Promise<void> => {
    setResetCount(resetCount + 1);
    setEditedEvent(event);
    setSaving(false);
    setDeleting(false);
  };

  const handleModalClose = async () => {
    onClose();
  };

  const onNameUpdated = (e: any) => {
    setEditedEvent({
      ...editedEvent,
      name: e.target.value,
    });
  };

  const onSelectLocation = (locationId: string) => {
    setEditedEvent({
      ...editedEvent,
      locationId,
    });
  };

  const resetEdits = () => {
    Modal.confirm({
      title: 'Reset Changes',
      content: 'Are you sure you want to reset your changes?',
      maskClosable: true,
      onOk: resetState,
    });
  };

  const onCategoriesChange = (categories: DayCategory[]) => {
    setEditedEvent({
      ...editedEvent,
      categories,
    });
  };

  const onScenesChange = (scenes: string[]) => {
    setEditedEvent({
      ...editedEvent,
      scenes,
    });
  };

  const saveEdits = async () => {
    setSaving(true);

    try {
      await schedulerService.updateEvent(editedEvent.locationId, editedEvent);
    } catch (err) {
      Modal.info({
        title: 'Could not save event',
        content: 'There was an error trying to save this event. Please try again.',
      });
    }

    setSaving(false);
    handleModalClose();
  };

  const handleDeleteEvent = () => {
    Modal.confirm({
      title: 'Delete Scene',
      content: 'Are you sure you want to delete this event?',
      maskClosable: true,
      icon: (
        <GoAlert style={{ color: 'red', fontSize: '22px', float: 'left', marginRight: '16px', marginBottom: '20px' }} />
      ),
      okText: 'Delete',
      onOk: async () => {
        setDeleting(true);

        try {
          await schedulerService.deleteEvent(editedEvent.locationId, editedEvent.id);
          handleModalClose();
        } catch (err) {
          Modal.info({
            title: 'Could not delete event',
            content: 'There was an error trying to delete this event. Please try again.',
          });
        }

        setDeleting(false);
      },
      okButtonProps: {
        type: 'primary',
        danger: true,
        loading: deleting,
      },
    });
  };

  const renderSelectLocation = () => {
    return (
      <div className='editEventSelectLocationWrapper'>
        <div className='editEventSelectLocationTitle'>Location</div>
        <Select
          key={`selectLocation-${resetCount}`}
          className='editEventSelectLocation'
          defaultValue={editedEvent.locationId}
          onChange={onSelectLocation}
          size='small'
        >
          {locations.map(location => {
            return (
              <Option key={location.id} value={location.id}>
                {location.name}
              </Option>
            );
          })}
        </Select>
      </div>
    );
  };

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color='#3db8ff'
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const renderSelectCategories = () => {
    const options = Object.values(DayCategory).map((category, index) => {
      return (
        <Option key={index} value={category}>
          {category}
        </Option>
      );
    });

    return (
      <div className='editEventSelectCategories'>
        <div className='editEventSelectCategoriesTitle'>Categories</div>
        <Select
          key={`selectCategories-${resetCount}`}
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          tagRender={tagRender}
          onChange={onCategoriesChange}
          defaultValue={editedEvent.categories}
        >
          {options}
        </Select>
      </div>
    );
  };

  const renderSelectScenes = () => {
    const options = scenes
      .filter(scene => scene.locationId === editedEvent.locationId)
      .map((scene, index) => {
        return (
          <Option key={index} value={scene.id}>
            {scene.name}
          </Option>
        );
      });

    return (
      <div className='editEventSelectCategories'>
        <div className='editEventSelectCategoriesTitle'>Scenes</div>
        <Select
          key={`selectScenes-${resetCount}`}
          mode='multiple'
          allowClear
          style={{ width: '100%' }}
          disabled={!editedEvent.locationId}
          tagRender={tagRender}
          onChange={onScenesChange}
          defaultValue={editedEvent.scenes}
          filterOption={(input: string, scene: any) => {
            return scene.children.toLocaleLowerCase().includes(input.toLocaleLowerCase());
          }}
        >
          {options}
        </Select>
      </div>
    );
  };

  const onStartUpdated = (eventTime: EventTime | ScheduledTime) => {
    setEditedEvent({
      ...editedEvent,
      start: eventTime,
    });
  };

  const onEndUpdated = (eventTime: EventTime | ScheduledTime) => {
    setEditedEvent({
      ...editedEvent,
      end: eventTime,
    });
  };

  const canSave = editedEvent.name.length > 0 && editedEvent.locationId;

  return (
    <>
      <div className='editEventNameWrapper'>
        <div className='editEventTitle'>Name</div>
        <Input className='editEventName' value={editedEvent.name} onChange={onNameUpdated} size='small' />
      </div>
      {renderSelectLocation()}
      {renderSelectCategories()}
      {renderSelectScenes()}
      <SetEventTime
        key={`editEventStart-${resetCount}`}
        title='Start'
        defaultValue={editedEvent.start}
        onChange={onStartUpdated}
      />
      <SetEventTime
        key={`editEventEnd-${resetCount}`}
        title='End'
        defaultValue={editedEvent.end}
        onChange={onEndUpdated}
      />
      <div className='editEventFooterButtons'>
        <div className='leftButtons'>
          {editedEvent.id && (
            <div className='editEventReset' onClick={resetEdits}>
              <FaUndo />
            </div>
          )}
          {editedEvent.id && (
            <div className='editEventDelete' onClick={handleDeleteEvent}>
              <FaTrashAlt />
            </div>
          )}
        </div>
        <div className='rightButtons'>
          <Button className='saveButton' type='primary' onClick={saveEdits} disabled={!canSave} loading={saving}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    locations: locationsSelector(state),
    scenes: scenesSelector(state),
  };
};

export default connect(mapStateToProps)(EditEvent);
