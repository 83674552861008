import { DevicesActionTypes, SYNC_DEVICES, SET_DEVICES_SYNCING } from 'types';

export function syncDevices(): DevicesActionTypes {
  return {
    type: SYNC_DEVICES,
  };
}

export function setDevicesSyncing(syncing: boolean): DevicesActionTypes {
  return {
    type: SET_DEVICES_SYNCING,
    syncing,
  };
}
