import 'components/Events/Events.scss';
import React, { useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RootState } from 'reducers/root.reducer';
import Search from 'antd/lib/input/Search';
import { Button, Modal } from 'antd';
import { Event, EventTime } from 'types';
import { eventsSelector, filtersSelector } from 'selectors';
import { setEventsFilter } from 'actions';
import EventCard from 'components/Events/EventCard';
import EditEvent from './EditEvent';

interface EventsProps extends DispatchProp {
  filter: string;
  events: Event[];
}

const Events: React.FC<EventsProps> = props => {
  const { dispatch, filter, events } = props;

  const defaultModalEvent = (): Event => ({
    id: '',
    locationId: '',
    name: '',
    categories: [],
    enabled: false,
    start: {} as EventTime,
    end: {} as EventTime,
    scenes: [],
  });

  const [showModal, setShowModal] = useState(false);
  const [modalEvent, setModalEvent] = useState(defaultModalEvent());

  const onSearch = (e: any) => {
    dispatch(setEventsFilter(e.target.value));
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalEvent(defaultModalEvent());
  };

  const openEditModal = (event: Event) => {
    setModalEvent(event);
    setShowModal(true);
  };

  const renderEvents = () => {
    return events.map((event, index) => (
      <EventCard key={index} event={event} openEditModal={() => openEditModal(event)} />
    ));
  };

  return (
    <>
      <div className='tabTitle'>
        <div className='eventsTabTitleText'>Events</div>
        <div className='eventsFilter'>
          <Search allowClear placeholder='Filter events...' onChange={onSearch} value={filter} />
          <Button className='devicesSyncButton' onClick={() => setShowModal(true)} type='primary'>
            New
          </Button>
          <Modal
            visible={showModal}
            onCancel={handleModalClose}
            title={modalEvent.id ? 'Edit Event' : 'New Event'}
            footer={null}
            destroyOnClose={true}
            centered
          >
            <EditEvent event={modalEvent} onClose={handleModalClose} />
          </Modal>
        </div>
      </div>
      <div className='tabBody'>{renderEvents()}</div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    events: eventsSelector(state),
    filter: filtersSelector(state).events,
  };
};

export default connect(mapStateToProps)(Events);
