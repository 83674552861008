import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCUxnbw9nZ65tZHF-FDvlYFUGBeeNPekk4',
  authDomain: 'shabbat-home.firebaseapp.com',
  projectId: 'shabbat-home',
  storageBucket: 'shabbat-home.appspot.com',
  messagingSenderId: '777506755053',
  appId: '1:777506755053:web:2b21c21e264d4f796157e4',
  measurementId: 'G-PK6897T3W9',
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const analytics = firebase.analytics();

export { firebase, auth, db, analytics };
