import { createSelector } from 'reselect';
import { RootState } from 'reducers/root.reducer';
import { Device, LocationsState } from 'types';

const getLocations = (state: RootState): LocationsState => state.locations;

export const devicesSelector = createSelector<RootState, LocationsState, Device[]>([getLocations], locations => {
  return Object.values(locations).reduce<Device[]>((acc, location) => {
    acc.push(...location.devices);

    return acc;
  }, []);
});
