import 'components/Scenes/Scenes.scss';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Slider, Switch } from 'antd';
import { Device } from 'types';
import { RootState } from 'reducers/root.reducer';
import { devicesSelector } from 'selectors';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

interface EditSceneProps {
  device: Device;
  defaultLevel: number;
  onChangeLevel: (device: Device, level: number) => void;
  onRemoveDevice: (device: Device) => void;
}

const EditSceneDevice: React.FC<EditSceneProps> = props => {
  const { device, defaultLevel, onChangeLevel, onRemoveDevice } = props;

  const [expanded, setExpanded] = useState(false);
  const [level, setLevel] = useState(defaultLevel);

  const footerClassName = expanded ? 'visible' : 'hidden';

  const onChecked = () => {
    onRemoveDevice(device);
  };

  const onAfterChange = (value: number) => {
    onChangeLevel(device, value);
  };

  const onSwitchChange = (checked: boolean) => {
    const newLevel = checked ? 100 : 0;

    setLevel(newLevel);
    onChangeLevel(device, newLevel);
  };

  return (
    <div className='sceneDevice'>
      <div className='sceneDeviceHeader'>
        <div className='editSceneDeviceNameWrapper' onClick={() => setExpanded(!expanded)}>
          <Checkbox checked={true} onChange={onChecked} />
          <div className='sceneDeviceName'>{device.deviceName}</div>
        </div>
        <div className='sceneDeviceLevelWrapper'>
          <div className='sceneDeviceLevel'>{level}%</div>
          <Switch checked={level > 0} onChange={onSwitchChange} size='small' />
          <div className='sceneDeviceExpandIcon' onClick={() => setExpanded(!expanded)}>
            {expanded ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>
      </div>
      <div className={`sceneDeviceFooter ${footerClassName}`}>
        <Slider value={level} onChange={setLevel} onAfterChange={onAfterChange} tooltipVisible={false} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    devices: devicesSelector(state),
  };
};

export default connect(mapStateToProps)(EditSceneDevice);
