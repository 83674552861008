import 'components/Schedule/Schedule.scss';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { Spin, Select, TimePicker, Modal } from 'antd';
import { getHours, getMinutes } from 'date-fns';
import moment from 'moment';
import { Dictionary, isEmpty } from 'lodash';
import * as schedulerService from 'services/scheduler.service';
import { Location } from 'types';
import { RootState } from 'reducers/root.reducer';
import { locationsSelector } from 'selectors';
import { connect } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { IoMdOpen } from 'react-icons/io';

const { Option } = Select;

interface ScheduleProps {
  locations: Location[];
}

const Schedule: React.FC<ScheduleProps> = props => {
  const { locations } = props;
  const now = new Date();

  const [selectedLocationId, setLocationId] = useState('');
  const [selectedDate, setDate] = useState(now);
  const [selectedHour, setHour] = useState(getHours(now));
  const [selectedMinute, setMinute] = useState(getMinutes(now));
  const [calculatedScene, setCalculatedScene] = useState<Dictionary<number>>({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getCalculatedScene();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocationId, selectedDate, selectedHour, selectedMinute]);

  useEffect(() => {
    if (!selectedLocationId && !isEmpty(locations)) {
      setLocationId(locations[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  const onDateChanged = (date: Date) => {
    setDate(date);
  };

  const onTimeChange = (date: Date) => {
    setHour(getHours(date));
    setMinute(getMinutes(date));
  };

  const getCalculatedDate = () => {
    return moment(selectedDate).hour(selectedHour).minute(selectedMinute).second(0).millisecond(0);
  };

  const getFormattedDate = () => {
    return getCalculatedDate().format('MM/DD/YYYY h:mm a');
  };

  const getCalculatedScene = async () => {
    if (!selectedLocationId) {
      return;
    }

    setLoading(true);

    const selectedTime = getCalculatedDate();
    const scheduledScene = await schedulerService.getScheduledScene(selectedLocationId, selectedTime.toDate());

    setCalculatedScene(scheduledScene);
    setLoading(false);
  };

  const onSelectLocation = (locationId: string) => {
    setLocationId(locationId);
  };

  const renderScheduledSceneContent = () => {
    if (loading) {
      return (
        <div className='emptyScheduledScene'>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    if (isEmpty(calculatedScene)) {
      return (
        <div className='emptyScheduledScene'>
          <div>Nothing is scheduled</div>
        </div>
      );
    }

    return (
      <div className='scheduledSceneDevices'>
        {Object.keys(calculatedScene)
          .sort()
          .map((key, index) => {
            return (
              <div className='scheduleDeviceInfo' key={index}>
                <div className='scheduleDeviceInfoName'>{key}</div>
                <div className='scheduleDeviceInfoLevel'>{calculatedScene[key]}%</div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderScheduledScene = () => {
    if (!selectedLocationId) {
      return null;
    }

    return (
      <div className='scheduleCalculatedScene'>
        <div className='divider' />
        <div className='scheduleCalculatedSceneTitle'>
          <div>Scheduled Scene</div>
          <div className='scheduleCalculatedSceneTitleRight'>
            <div className='scheduleCalculatedSceneDate'>{getFormattedDate()}</div>
            <IoMdOpen className='scheduleCalculatedSceneDateIcon' onClick={() => setShowModal(true)} />
          </div>
        </div>
        {renderScheduledSceneContent()}
      </div>
    );
  };

  return (
    <div className='scheduleWrapper'>
      <div className='scheduleCalendar'>
        <Calendar onChange={date => onDateChanged(date as Date)} value={selectedDate} calendarType='US' />
      </div>
      <div className='scheduleTimeAndPlaceForm'>
        <div className='scheduleTimePickerContainer'>
          <div className='scheduleTimePickerTitle'>Time</div>
          <div className='scheduleTimePicker'>
            <TimePicker
              format='h:mm a'
              size='small'
              defaultValue={moment(now)}
              onChange={e => {
                if (e) {
                  onTimeChange(e.toDate());
                }
              }}
            />
          </div>
        </div>
        <div className='scheduleSelectLocationWrapper'>
          <div className='scheduleSelectLocationTitle'>Location</div>
          <Select
            className='scheduleSelectLocation'
            onChange={onSelectLocation}
            size='small'
            value={selectedLocationId}
          >
            {locations.map(location => {
              return (
                <Option key={location.id} value={location.id}>
                  {location.name}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
      <div className='scheduleCalculatedSceneWrapper'>{renderScheduledScene()}</div>
      <Modal
        visible={showModal}
        destroyOnClose={true}
        title={`Scheduled Scene `}
        footer={null}
        onCancel={() => setShowModal(false)}
        centered
        bodyStyle={{ paddingTop: '10px', paddingBottom: '10px' }}
      >
        <div className='scheduleCalculatedSceneModalWrapper'>
          <div className='scheduleCalculatedSceneModalTitle'>{getFormattedDate()}</div>
          <div className='scheduleCalculatedSceneModalContent'>{renderScheduledSceneContent()}</div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    locations: locationsSelector(state),
  };
};

export default connect(mapStateToProps)(Schedule);
