import { createSelector } from 'reselect';
import { RootState } from 'reducers/root.reducer';
import { LocationsState, Scene } from 'types';

const getLocations = (state: RootState): LocationsState => state.locations;

export const scenesSelector = createSelector<RootState, LocationsState, Scene[]>([getLocations], locations => {
  return Object.values(locations).reduce<Scene[]>((scenes, location) => {
    const mappedScenes = Object.values(location.scenes)
      .map(scene => {
        return {
          ...scene,
          devices: [...scene.devices].sort((a, b) => (a.deviceName < b.deviceName ? -1 : 1)),
        };
      })
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    return scenes.concat(...mappedScenes);
  }, []);
});
