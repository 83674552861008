import { DELETE_LOCATION, Location, LocationsActionTypes, RUN_SCENE, SEND_COMMAND, SET_LOCATION } from 'types';

export function setLocation(location: Location): LocationsActionTypes {
  return {
    type: SET_LOCATION,
    location,
  };
}

export function sendCommand(locationId: string, deviceId: string, level: number): LocationsActionTypes {
  return {
    type: SEND_COMMAND,
    locationId,
    deviceId,
    level,
  };
}

export function runScene(locationId: string, sceneId: string): LocationsActionTypes {
  return {
    type: RUN_SCENE,
    locationId,
    sceneId,
  };
}

export function deleteLocation(locationId: string): LocationsActionTypes {
  return {
    type: DELETE_LOCATION,
    locationId,
  };
}
