import 'components/Main.scss';
import React from 'react';
import { connect } from 'react-redux';
import FooterTabs from 'components/FooterTabs/FooterTabs';
import { RootState } from 'reducers/root.reducer';
import { currTabSelector } from 'selectors';
import { Redirect } from 'react-router';
import { Tabs } from 'types';
import Devices from 'components/Devices/Devices';
import Scenes from 'components/Scenes/Scenes';
import Events from 'components/Events/Events';
import Locations from 'components/Locations/Locations';
import User from 'components/User/User';
import Header from 'components/Header/Header';
import Schedule from 'components/Schedule/Schedule';

interface MainProps {
  currTab: string;
}

const Main: React.FC<MainProps> = props => {
  const { currTab } = props;

  const renderCurrTab = () => {
    switch (currTab) {
      case Tabs.DEVICES: {
        return <Devices />;
      }
      case Tabs.SCENES: {
        return <Scenes />;
      }
      case Tabs.EVENTS: {
        return <Events />;
      }
      case Tabs.SCHEDULE: {
        return <Schedule />;
      }
      case Tabs.LOCATIONS: {
        return <Locations />;
      }
      case Tabs.USER: {
        return <User />;
      }
      default: {
        return <Redirect to='/views/devices' />;
      }
    }
  };

  return (
    <div className='main'>
      <Header />
      <div className='tabContents'>{renderCurrTab()}</div>
      <FooterTabs />
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    currTab: currTabSelector(state),
  };
};

export default connect(mapStateToProps)(Main);
