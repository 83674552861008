import 'components/Scenes/Scenes.scss';
import React, { useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RootState } from 'reducers/root.reducer';
import Search from 'antd/lib/input/Search';
import { Button, Modal } from 'antd';
import { setScenesFilter } from 'actions';
import { filteredScenesSelector, filtersSelector } from 'selectors';
import { Dictionary } from 'lodash';
import SceneCard from 'components/Scenes/SceneCard';
import { Scene } from 'types';
import EditScene from 'components/Scenes/EditScene';

interface ScenesProps extends DispatchProp {
  filter: string;
  scenes: Scene[];
}

const Scenes: React.FC<ScenesProps> = props => {
  const { dispatch, filter, scenes } = props;
  const defaultModalScene = () => ({
    id: '',
    name: '',
    locationId: '',
  });

  const [showModal, setShowModal] = useState(false);
  const [modalScene, setModalScene] = useState<{ id: string; name: string; locationId: string }>(defaultModalScene());
  const [modalSceneDevices, setModalSceneDevices] = useState<Dictionary<number>>({});

  const onSearch = (e: any) => {
    dispatch(setScenesFilter(e.target.value));
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalScene(defaultModalScene());
    setModalSceneDevices({});
  };

  const openEditModal = (scene: Scene) => {
    const sceneDevices = scene.devices.reduce<Dictionary<number>>((acc, device) => {
      acc[device.deviceId] = device.level;
      return acc;
    }, {});

    setModalScene(scene);
    setModalSceneDevices(sceneDevices);
    setShowModal(true);
  };

  const renderScenes = () => {
    return scenes.map((scene, index) => (
      <SceneCard key={index} scene={scene} openEditModal={() => openEditModal(scene)} />
    ));
  };

  return (
    <>
      <div className='tabTitle'>
        <div className='scenesTabTitleText'>Scenes</div>
        <div className='scenesFilter'>
          <Search allowClear placeholder='Filter scenes...' onChange={onSearch} value={filter} />
          <Button className='devicesSyncButton' onClick={() => setShowModal(true)} type='primary'>
            New
          </Button>
          <Modal
            visible={showModal}
            onCancel={handleModalClose}
            title={modalScene.id ? 'Edit Scene' : 'New Scene'}
            footer={null}
            destroyOnClose={true}
            centered
          >
            <EditScene scene={modalScene} sceneDevices={modalSceneDevices} onClose={handleModalClose} />
          </Modal>
        </div>
      </div>
      <div className='tabBody'>{renderScenes()}</div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    scenes: filteredScenesSelector(state),
    filter: filtersSelector(state).scenes,
  };
};

export default connect(mapStateToProps)(Scenes);
