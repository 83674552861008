import axios, { AxiosResponse } from 'axios';
import { Modal } from 'antd';
import { Dictionary } from 'lodash';
import { auth } from 'fire';

export default class AxiosWrapper {
  private readonly baseURL: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  public async get<T>(url: string): Promise<AxiosResponse<T>> {
    const instance = await this.axiosInstance();

    return instance.get(url).catch(this.handleError);
  }

  public async post<T>(url: string, body?: Dictionary<any>): Promise<AxiosResponse<T>> {
    const instance = await this.axiosInstance();

    return instance.post(url, body).catch(this.handleError);
  }

  public async put<T>(url: string, body?: Dictionary<any>): Promise<AxiosResponse<T>> {
    const instance = await this.axiosInstance();

    return instance.put(url, body).catch(this.handleError);
  }

  public async deleteRequest<T>(url: string): Promise<AxiosResponse<T>> {
    const instance = await this.axiosInstance();

    return instance.delete(url).catch(this.handleError);
  }

  private async axiosInstance() {
    const token = await auth.currentUser?.getIdToken();

    return axios.create({
      baseURL: this.baseURL,
      headers: {
        crossdomain: true,
        Authorization: `Bearer ${token}`,
      },
      timeout: 120000,
    });
  }

  private handleError(error: any) {
    console.error(error);

    if (!error.response) {
      Modal.error({
        title: 'Sorry about this!',
        content: 'Our server is having some technical difficulties. Please check back with us later.',
      });

      return error;
    }

    switch (error.response.status) {
      case 401: {
        auth.signOut();
        window.location.href = '/';
        break;
      }
      case 403: {
        Modal.error({
          title: 'Permission Denied',
          content: `Sorry, you don't have permission to view this information.`,
        });

        break;
      }
      case 500: {
        Modal.error({
          title: 'Internal Server Error',
          content: 'We ran into some technical difficulties. Please try again.',
        });

        break;
      }
      default: {
        Modal.error({
          title: 'Sorry about this!',
          content: 'We encountered an unexpected error. Please try refreshing the page.',
        });

        break;
      }
    }

    return error;
  }
}
