import 'components/Scenes/Scenes.scss';
import React, { useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { MdEdit } from 'react-icons/md';
import { IoMdPower } from 'react-icons/io';
import { Scene } from 'types';
import { runScene } from 'actions';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

interface SceneCardProps extends DispatchProp {
  scene: Scene;
  openEditModal: () => void;
}

const SceneCard: React.FC<SceneCardProps> = props => {
  const { scene, dispatch, openEditModal } = props;

  const [expanded, setExpanded] = useState(false);

  const handleRunScene = () => {
    dispatch(runScene(scene.locationId, scene.id));
  };

  const renderDevices = () => {
    return scene.devices.map((device, index) => {
      return (
        <div className='sceneDevice' key={index}>
          <div className='sceneDeviceNameWrapper'>
            <div className='sceneDeviceName'>{device.deviceName}</div>
            <div className='sceneDeviceLevel'>{device.level}%</div>
          </div>
        </div>
      );
    });
  };

  const sceneCardBodyClass = expanded ? 'visible' : 'hidden';

  return (
    <div className='sceneCard'>
      <div className='sceneCardHeader'>
        <div className='sceneCardTitle' onClick={() => setExpanded(!expanded)}>
          {scene.name}
        </div>
        <div className='sceneCardActions'>
          <MdEdit className='editSceneButton' onClick={openEditModal} />
          <IoMdPower className='activateSceneButton' onClick={handleRunScene} />
          <div className='sceneCardExpandIcon' onClick={() => setExpanded(!expanded)}>
            {expanded ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        </div>
      </div>
      <div className={`sceneCardBody ${sceneCardBodyClass}`}>{renderDevices()}</div>
    </div>
  );
};

export default connect()(SceneCard);
